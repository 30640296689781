<template>
  <UsersLayout @chosenDeport="chosenDeport">
    <v-simple-table class="my-table" :dense="!isLarge">
      <template v-slot:default>
        <UsersHeader @filter="onFilter" :reset="resetFilter" />

        <tbody name="fade" is="transition-group">
          <UserRow
            v-for="(user, index) in users"
            :key="user.uuid"
            :user="user"
            :index="index"
            @delete="deleteUser"
            @resetFilter="reset"
            @toggleRow="toggleRow"
            :class="{ 'active-row': activeRow == user.uuid }"
          />
        </tbody>
      </template>
    </v-simple-table>
    <div v-if="!loading && users.length === 0" class="text-center">
      {{ $t("table.noData") }}
    </div>
    <v-progress-linear v-if="isLoadingLine" indeterminate color="primary"></v-progress-linear>
    <div
      v-observer="{ nextPage: nextPage }"
      class="observer"
      v-if="!loadMoreDisabled && !loading"
    ></div>
    <ConfirmDeleteDialog
      :visible="confirmDelete"
      :uuid="uuidToDelete"
      @close="confirmDelete = false"
      @delete="deleteUser"
      v-if="canDelete"
    />
    <EditUserDialog
      v-if="editUser && canEdit"
      @close="
        editUserDialog = false;
        editUser = '';
      "
      :user-item="editUser"
      :visible="editUserDialog"
    />
  </UsersLayout>
</template>

<script>
import UsersLayout from "./UsersLayout.vue";
import loader from "../../mixins/loader";
import notifications from "../../mixins/notifications";
import usersService from "../../services/request/users/usersService";
import ConfirmDeleteDialog from "../../components/dialog/ConfirmDeleteDialog.vue";
import EditUserDialog from "../../components/users/EditUserDialog.vue";
import usersMixin from "./usersMixin";
import user from "../../mixins/user";
import UserRow from "./UserRow.vue";
import paginationMixin from "../../mixins/paginationMixin";
import UsersHeader from "./UsersHeader.vue";
import SizeUi from "../../mixins/SizeUi";

export default {
  name: "Employees",
  components: {
    ConfirmDeleteDialog,
    EditUserDialog,
    UserRow,
    UsersLayout,
    UsersHeader
  },
  mixins: [loader, notifications, usersMixin, user, paginationMixin, SizeUi],
  data: () => ({
    users: [],
    loadMoreDisabled: false,
    resetFilter: "",
    chosenDeportItem: "",
    activeRow: "",
    isLoadingLine: false,
    loading: true
  }),
  methods: {
    toggleRow(e) {
      this.activeRow = e;
    },
    reset(e) {
      this.resetFilter = e;
    },
    chosenDeport(event) {
      this.chosenDeportItem = event;
      console.log(this.chosenDeportItem);
      this.onSelectUser();
    },
    async onSelectUser() {
      try {
        this.setLoading(true);
        const params = {};
        if (this.chosenDeportItem) {
          params.uuid_deport = this.chosenDeportItem;
          this.users = await usersService.getEmployeesList({
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage,
            ...params,
            order: "DESC",
            orderby: "time_created"
          });
        } else {
          this.users = await usersService.getEmployeesList({
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage,
            ...params,
            order: "DESC",
            orderby: "time_created"
          });
        }
        if (this.users) {
          this.loadMoreDisabled = this.users.length < this.perPage;
        } else {
          this.loadMoreDisabled = true;
        }
        this.setLoading(false);
      } catch (e) {
        this.setLoading(false);
        this.setErrorNotification(e);
      }
    },
    async getUsersList(props = {}, isLoadLine, updated = false) {
      try {
        if (isLoadLine) {
          this.isLoadingLine = true;
        }
        this.loading = true;
        const params = {};
        params.offset = updated ? 0 : (this.page - 1) * this.perPage;
        params.limit = updated ? this.largeLimit : this.perPage;
        const newItems = await usersService.getEmployeesList({
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          ...props,
          order: "DESC",
          orderby: "time_created",
          ...params
        });
        if (newItems) {
          this.users.push(...newItems);
          this.users = this.getUniqueUsers(this.users);
          this.loadMoreDisabled = newItems.length < this.perPage;
        } else {
          this.loadMoreDisabled = true;
        }
        if (isLoadLine) {
          this.isLoadingLine = false;
        }
        this.setLoading(false);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        if (isLoadLine) {
          this.isLoadingLine = false;
        } else {
          this.setLoading(false);
        }
        this.setErrorNotification(e);
      }
    }
  }
};
</script>

<style lang="scss"></style>
